<template>
  <ContentWrapper :form-title="title">
    <template v-slot:default>
      <div class="welcome-wr">
      </div>
    </template>
    <template v-slot:buttons>
      <button @click="resetWizardData ()" class="ui-button ui-button--dark">Try again</button>
    </template>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from '@/components/layout/ContentWrapper'

export default {
  name: 'SettingsChangedError',
  components: { ContentWrapper },
  data () {
    return {
      title: 'Oops, your practice just made a change..click try again below.'
    }
  },
  methods: {
    resetWizardData () {
      location.reload()
    }
  }
}
</script>

<style scoped>

</style>
