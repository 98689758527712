<template>
  <div class="footer">
    <p>Powered by</p>
    <img src="../../assets/logo-footer.png">
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped lang="scss">
  .footer {
    position: absolute;
    bottom: 8px;
    display: block;
    left: 0;
    text-align: center;
    box-sizing: border-box;
    width: 100%;

    p {
      padding: 0;
      margin: 0;
      font-size: 11px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      color: #A3A3A3;
      display: inline;
      line-height: 30px;
    }

    img {
      padding: 0;
      height: 30px;
      vertical-align: bottom;
    }
  }
</style>
