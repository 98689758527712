<template>
  <div class="wizard-wr__stepper">
    <div class="ui-heading">Book an Appointment</div>
    <div class="stepper stepper-mobile">
      <div class="step">
        <div class="text" :class="{'active':value >= 1,'focus':value == 1}">
          <label>Step 1</label>
          <p>Date & Time</p>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 2,'focus':value == 2}">
        <div class="text">
          <label>Step 2</label>
          <p>Basic Details</p>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 3,'focus':value == 3}">
        <div class="text">
          <label>Step 3</label>
          <p>Photos</p>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 4,'focus':value == 4}">
        <div class="text">
          <label>Step 4</label>
          <p>Medical History</p>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 5,'focus':value == 5}">
        <div class="text">
          <label>Step 5</label>
          <p>Survey</p>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 6,'focus':value == 6}">
        <div class="text">
          <label>Step 6</label>
          <p>Payment</p>
        </div>
      </div>
    </div>
    <div class="stepper">
      <div class="step"  :class="{'active':value >= 1,'focus':value == 1}">
        <div class="text">
          <label>Step 1</label>
          <p>Date & Time</p>
        </div>
        <div class="bullet">
          <span class="icon"></span>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 2,'focus':value == 2}">
        <div class="text">
          <label>Step 2</label>
          <p>Basic Details</p>
        </div>
        <div class="bullet">
          <span class="icon"></span>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 3,'focus':value == 3}">
        <div class="text">
          <label>Step 3</label>
          <p>Photos</p>
        </div>
        <div class="bullet">
          <span class="icon"></span>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 4,'focus':value == 4}">
        <div class="text">
          <label>Step 4</label>
          <p>Medical History</p>
        </div>
        <div class="bullet">
          <span class="icon"></span>
        </div>
      </div>
      <div class="step"  :class="{'active':value >= 5,'focus':value == 5}">
        <div class="text">
          <label>Step 5</label>
          <p>Survey</p>
        </div>
        <div class="bullet">
          <span class="icon"></span>
        </div>
      </div>
      <div class="step" :class="{'active':value >= 6,'focus':value == 6}">
        <div class="text">
          <label>Step 6</label>
          <p>Payment</p>
        </div>
        <div class="bullet">
          <span class="icon"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepperHeader',
  props: {
    value: Number
  }
}
</script>
