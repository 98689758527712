<template>
  <div class="FormDate" style="text-align: left;padding-bottom: 10px;" @keyup.capture="updateValue">
    <span style="color: #a3a3a3;">DOB *</span>
    <div style="display: inline-block;margin-top: 5px;">
    <input
      v-if="showDay"
      ref="day"
      name="day"
      v-model="day"
      class="FormDate__input FormDate__input--day"
      type="number"
      placeholder="dd"
      @input="updateDay($event)"
      @keypress="isNumber($event)"
    />
      <!-- @blur="day = day.padStart(2, 0)" -->
    <span v-if="showDay && showMonth" class="FormDate__divider">/</span>
    <input
      v-if="showMonth"
      ref="month"
      name="month"
      v-model="month"
      class="FormDate__input FormDate__input--month"
      type="number"
      placeholder="mm"
      @input="updateMonth($event)"
      v-on:keyup.delete="monthBack"
      @keypress="isNumber($event)"
    />
      <!-- @blur="month = month.padStart(2, 0)" -->
    <span v-if="showYear && (showDay || showMonth)" class="FormDate__divider">/</span>
    <input
      v-if="showYear"
      ref="year"
      v-model="year"
      class="FormDate__input FormDate__input--year"
      type="number"
      name="year"
      placeholder="yyyy"
      v-on:keyup.delete="yearBack"
      @keypress="isNumber($event)"
    />
    </div>
      <!-- @blur="year = year.padStart(4, 0)" -->
  </div>
</template>

<script>
export default {
  name: 'DateInput',
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    showDay: {
      type: Boolean,
      default: true
    },
    showMonth: {
      type: Boolean,
      default: true
    },
    showYear: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      onetime: false,
      day: `${this.value ? new Date(this.value).getDate() : ''}`,
      month: `${this.value ? new Date(this.value).getMonth() + 1 : ''}`,
      year: `${this.value ? new Date(this.value).getFullYear() : ''}`
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.onetime = true
    })
  },
  watch: {
    value (current, prev) {
      if (!this.onetime) {
        this.onetime = true
        this.day = `${current !== '' ? new Date(current).getDate() : ''}`
        this.month = `${current !== '' ? new Date(current).getMonth() + 1 : ''}`
        this.year = `${current !== '' ? new Date(current).getFullYear() : ''}`
      }
    },
    day (current, prev) {
      if (current.length > 1 && (current < 1 || current > 31)) this.day = prev
      if (current.length > 2) this.day = prev
    },
    month (current, prev) {
      if (current.length > 1 && (current < 1 || current > 12)) this.month = prev
      if (current.length > 2) this.month = prev
    },
    year (current, prev) {
      if (current > new Date().getFullYear() - 5) this.year = prev
      if (current.length > 4) this.year = prev
    }
  },
  methods: {
    monthBack () {
      if (this.month.length < 1) this.$refs.day.focus()
    },
    yearBack () {
      if (this.year.length < 1) this.$refs.month.focus()
    },
    updateDay (val) {
      if (!this.day.length || parseInt(this.day, 10) < 4) return
      if (this.showMonth) this.$refs.month.select()
      else if (this.showYear) this.$refs.year.select()
      if (this.day.length === 3) {
        this.month = val.data + this.month
        this.day = this.day[0] + this.day[1]
      }
      if (this.day.length === 2 && this.day > 31) {
        this.month = val.data + this.month
        this.day = this.day[0]
      }
    },
    updateMonth (val) {
      if (!this.month.length || parseInt(this.month, 10) < 2) return
      if (this.showYear) this.$refs.year.select()
      if (this.month.length === 3) {
        this.year = val.data + this.year
        this.month = this.month[0] + this.month[1]
      }
      if (this.month.length === 2 && this.month > 12) {
        this.year = val.data + this.year
        this.month = this.month[0]
      }
    },
    updateValue () {
      this.$emit('input', null)
      if (this.day.length < 1 || this.day < 1) return
      if (this.month.length < 1 || this.month < 1) return
      if (this.year.length < 4 || this.year < 1) return
      const timestamp = new Date(
        `${this.year.padStart(4, 0)}/${this.month.padStart(2, 0)}/${this.day.padStart(2, 0)}`
      )
      if (timestamp.getFullYear() < (new Date().getFullYear() - 150)) return
      if (timestamp === 'Invalid Date') return
      this.$emit('input', timestamp)
    },
    isNumber: function (evt) {
      evt = (evt) || window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss">
.FormDate {
  $spacing: 0.1em;
  width: 100%;
  // height: 33px;
  outline: 0;
  padding: 3px 8px;
  margin: 0 0 10px 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-bottom: 1px solid #a3a3a3;
  // background-color: $color-background;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #1a1a1a;
  border-radius: 6px 6px 0 0;
  // 1. Hide the spinner button in Chrome, Safari and Firefox.
  &__input {
    padding: $spacing;
    padding-right: $spacing / 2;
    padding-left: $spacing / 2;
    border: none;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #1a1a1a;
    background-color: transparent;
    text-align: center;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield; // 1
    &::-webkit-inner-spin-button {
      display: none; // 1
    }
    &:first-child {
      padding-left: $spacing;
    }
    &:last-child {
      padding-right: $spacing;
    }
    &:focus {
      outline: none;
    }
    &--day,
    &--month {
      width: 2.5em;
    }
    &--year {
      width: 3em;
    }
  }
  &__divider {
    padding-top: $spacing;
    padding-bottom: $spacing;
    pointer-events: none;
  }
}
</style>
