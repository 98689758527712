<template>
  <div class="ui-input" :class="error">
    <input :type="type" v-if="type != 'date'" maxlength="50" :value="value" :name="name" :max="max" :placeholder="placeholder" @input="handleInput($event)">
    <!-- <label>{{ errMessage }}</label> -->
    <DateInput v-if="type=='date'" :class="error" @input="handleInput($event)" :value="value" />
    <!-- <datetime input-id="datepick" class="theme-pink" :value="value" @input="handleInput($event)" :max-datetime="max" format="dd-MM-yyyy" v-if="type=='date'"/> -->
    <label v-if="validation.required==false && validation.$anyError">This field is required</label>
    <label v-if="validation.email==false && validation.$anyError">Invalid Email</label>
    <label v-if="validation.numeric==false && validation.$anyError">Invalid contact number</label>
    <label v-if="validation.minLength==false && validation.$anyError">Least 7 numbers required</label>
    <label v-if="validation.maxLength==false && validation.$anyError">Allowed upto 16 numbers only</label>
    <label v-if="validation.contactNumber==false && validation.$anyError">Invalid contact number</label>
  </div>
</template>

<script>
// import { Datetime } from 'vue-datetime'
import moment from 'moment'
import DateInput from '@/components/ui/DateInput'

export default {
  name: 'InputField',
  props: ['type', 'value', 'placeholder', 'validation', 'max', 'error', 'errMessage', 'onfocus', 'onblur', 'name'],
  model: {
    prop: 'value',
    event: 'input'
  },
  // components: { datetime: Datetime },
  components: { DateInput },
  methods: {
    handleInput (e) {
      if (this.type !== 'date') {
        this.$emit('input', e.target.value)
      } else {
        if (!e) {
          this.$emit('input', '')
          return
        }
        e = new Date(e)
        if (e != null) this.$emit('input', moment(e).format('YYYY/MM/DD'))
      }
    },
    ifFun (bool, t, f) {
      return bool ? t : f
    }
  },
  mounted () {
    // document.querySelector('#datepick').placeholder = 'Date of Birth *'
  }
}
</script>

<style scoped lang="scss">
  // @import '../../../vue-datetime/dist/vue-datetime.css';
  @import '../../style/style';

    .ui-input {
    display: inline-block;
    width: 100%;
    position: relative;

    input {
      width: 100%;
      height: 40px;
      outline: 0;
      padding: 10px 8px;
      margin: 0 0 10px 0;
      box-sizing: border-box;
      outline: none;
      border: none;
      border-bottom: 1px solid #A3A3A3;
      background-color: $color-background;
      font-size: 13px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      color: #1A1A1A;
      border-radius: 6px 6px 0 0;

      &::placeholder {
        color: #a3a3a3;
      }

      &:focus {
        border-bottom-color: $color-primary;
      }
    }

    &.error {
      input {
        border-color: $color-error;
      }
      label {
        position: absolute;
        bottom: 0;
        left: 0;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 11px;
        margin: 0 0 -5px 8px;
        overflow: visible;
        z-index: 1;
        color: $color-error;
        background-color: $color-background;
      }
      div {
         border-color: $color-error;
      }
    }

    @media (min-width: $breakpoint-tablet) {
      width: 50%;

      &:nth-of-type(even) {
        padding-left: 15px;
      }

      &:nth-of-type(odd) {
        padding-right: 15px;
      }
    }
  }
</style>
