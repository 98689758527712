<template>
  <label class="container ui-button--time-select">
    <input type="radio" :checked="checked" :name="name" :disabled="disabled" :value="value">
    <span class="checkmark"></span>
    <span class="text"><slot/></span>
  </label>
  <!--    <button class="ui-button ui-button&#45;&#45;time-select" :class="type" :disabled="disabled"><slot/></button>-->
</template>

<script>
export default {
  name: 'TimeSelect',
  props: ['name', 'disabled', 'checked', 'value']
}
</script>

<style scoped lang="scss">
  @import '../../style/style';
  /* The container */
  .ui-button--time-select {
    cursor: default;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin: 0;
    padding: 0;
    min-width: 90px;
    height: 38px;
    box-shadow: none;
    border-radius: 0;
    overflow: visible;

    /* Hide the browser's default radio button */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 38px;
      width: 100%;
      width: calc(100% - 4px);
      margin: 1px 2px 3px 2px;
      display: inline-block;
      border-radius: 30px;
      background-color: #FFFFFF;
      box-shadow: inset 0px 0px 0px 1px #DEDEDE;
      z-index: 0;
      cursor: pointer;
    }

    .text {
      z-index: 1;
      font-size: 12px;
      color: #707070;
      margin-top: 2px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      cursor: pointer;
    }
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: $color-primary;
    box-shadow: none;
  }

  .container:hover input ~ .text {
    color: #FFFFFF;
  }

  /* When the radio button is checked */
  .container input:checked ~ .checkmark {
    background-color: $color-primary;
    box-shadow: 5px 5px 15px $color-shadow;
    cursor: default;
  }

  .container input:checked ~ .text {
    color: #FFFFFF;
    cursor: default;
  }

  /* When the radio button is disabled */
  .container input:disabled ~ .checkmark {
    background-color: #EDEDED;
    box-shadow: none;
    cursor: default;
  }

  .container input:disabled ~ .text {
    color: #B9B9B9;
    cursor: default;
  }
</style>
