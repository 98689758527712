import { apiUrl } from '../config'
import Axios from 'axios'

export default {
  loadClinicData ({ commit }) {
    var url = new URL(window.location)
    Axios.get(apiUrl + '/' + url.searchParams.get('id')).then(res => {
      setTimeout(function () {
        commit('setClinicData', res.data)
      }, 1500)
    })
  },

  loadDoctorsData ({ commit }) {
    var url = new URL(window.location)
    Axios.get(apiUrl + '/' + url.searchParams.get('id')).then(res => {
      setTimeout(function () {
        commit('setClinicData', res.data)
      }, 1500)
    })
  },

  loadIssueData ({ commit }) {
    var url = new URL(window.location)
    Axios.get(apiUrl + '/' + url.searchParams.get('id')).then(res => {
      setTimeout(function () {
        commit('setClinicData', res.data)
      }, 1500)
    })
  },
  checkIsThisClinicRelatedToAFreePlan: (clinicId) => Axios.post(`${apiUrl}/${clinicId}/verify`, { id: clinicId }),
  checkIsRelatedGroupBlocked: (clinicId) => Axios.post(`${apiUrl}/${clinicId}/verify-status`, { id: clinicId })

}
