<template>
  <ContentWrapper form-title="Appointment booked successfully.">
    <template v-slot:default>
      <div class="success-wr">
        <img class="icon" src="../assets/ic_success@2x.png">
        <p class="text">Thank you, a confirmation email and link has been sent. Your appointment has been booked for:</p>
        <div class="info">
          <div class="ui-label-time-select">{{ wizardData.selectedSlot.start_timestamp | moment('dddd DD') }} at {{ wizardData.selectedSlot.start_timestamp | moment('HH:mm') }} - {{ wizardData.selectedSlot.end_timestamp | moment('HH:mm')
          }}</div>
          <DoctorInfo :doc-name="wizardData.selectedDoctor.name" :photo="wizardData.selectedDoctor.photo_url" :doc-title="wizardData.selectedDoctor.title"/>
        </div>
        <p class="text">Also feel free to copy and keep the link below:</p>
        <div class="copy">
          <span>{{ finalResult.success }}</span>
          <button class="ui-button ui-button--dark" @click.stop.prevent="copyTestingCode">
              Copy
          </button>
          <input type="hidden" id="testing-code" :value="finalResult.success">
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button @click="resetWizardData()" class="ui-button ui-button--dark">Leave</button>
    </template>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from '@/components/layout/ContentWrapper'
import DoctorInfo from '@/components/ui/DoctorInfo'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'BookAppSuccess',
  components: { DoctorInfo, ContentWrapper },
  computed: {
    ...mapGetters(['wizardData', 'finalResult'])
  },
  data: function () {
    return {
      testingCode: 'https://chairsyde.com//SH/083692673'
    }
  },
  filters: {
    moment: function (val, format) {
      return moment(val).format(format)
    }
  },
  methods: {
    ...mapActions(['resetData']),
    resetWizardData () {
      location.reload()
    },
    copyTestingCode () {
      const testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        // alert('Copied ' + msg)
        this.$toasted.show('Copied ' + msg, {
          position: 'top-center',
          duration: 1000
        })
      } catch (err) {
        this.$toasted.show('Oops, unable to copy', {
          position: 'top-center',
          duration: 1000
        })
        // alert('Oops, unable to copy')
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../style/style';
  .success-wr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: inherit;
    .icon {
      background-color: #dedede;
      width: 48px;
      height: 36px;
      border-radius: 50%;
      padding: 32px 26px;
      box-sizing: content-box;
      zoom: 0.8;
      margin: 40px auto 10px;
    }
    .text {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      color: #707070;
      font-size: 13px;
      margin: 30px 0 20px;
      max-width: 400px;
      padding: 0 30px;
    }
    .info {
      display: flex;
      flex-direction: column;
      min-width: 260px;
      align-items: center;
      .ui-label-time-select {
        height: 48px;
        line-height: 48px;
        margin-right: 0;
        margin-bottom: 20px;
        font-size: 13px;
      }
      .doctor {
        height: 50px;
        &__name {
          font-weight: 700;
        }
      }
    }
    .copy {
      background: #FFFFFF;
      border: 1px solid #DEDEDE;
      border-radius: 45px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 400px;
      width: 80%;
      padding-left: 20px;
      color: #B9B9B9;
      overflow: hidden;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 30px;
      box-sizing: content-box;
      position: relative;
      .ui-button {
        border: 1px solid #DEDEDE;
        height: 50px;
        min-width: 90px;
        /*background-color: #FFFFFF;*/
        /*color: #B9B9B9;*/
        font-size: 16px;
        font-weight: 500;
        -webkit-box-shadow: -10px 0px 10px -7px rgba(255,255,255,1);
        -moz-box-shadow: -10px 0px 10px -7px rgba(255,255,255,1);
        box-shadow: -10px 0px 10px -7px rgba(255,255,255,1);
        position: absolute;
        right: -1px;
      }
      > span {
        height: 20px;
        overflow: hidden;
        text-align: left;
        word-break: break-all;
      }
    }
    @media (min-width: $breakpoint-tablet) {
      .icon {
        zoom: 1;
        margin: auto;
      }
      .info {
        flex-direction: row;
        .ui-label-time-select {
          margin-right: 20px;
          margin-bottom: 0;
        }
      }
    }
  }
</style>
