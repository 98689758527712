<template>
  <ContentWrapper formTitle="Book a Video Consultation">
    <template v-slot:default>
      <div class="select-doc">
        <h1 class="ui-sub-heading">Please select a clinician you wish to make an appointment with :</h1>
        <div class="select-doc__wr">
          <!--        This is the carousel slide    -->
                     <!-- :title="Dentist" -->
          <SelectDoc v-for="doctor in doctorList" :key="doctor.id" @click.native="doctor.status ? sellectADoctor(doctor) : null"
                     :name="doctor.name"
                     :title="doctor.title"
                     :photo="doctor.photo_url"
                     :disable="!doctor.status"
                     :active="wizardData.selectedDoctor.id==doctor.id"/>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <router-link @click.native="goPrev()" to="/" tag="button" class="ui-button ui-button--secondary">Back</router-link>
      <router-link @click.native="goNext()" to="/select-booking/step1" :disabled="!wizardData.selectedDoctor.id" tag="button" class="ui-button ui-button--primary">Next</router-link>
    </template>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from '@/components/layout/ContentWrapper'
import SelectDoc from '@/components/ui/SelectDoc'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BookAppSelectDoc',
  components: {
    SelectDoc,
    ContentWrapper
  },
  methods: {
    ...mapActions(['goPrev', 'goNext', 'loadDoctorList', 'sellectADoctor', 'checkRoute'])
  },
  computed: {
    ...mapGetters(['doctorList', 'wizardData'])
  },
  mounted () {
    this.loadDoctorList()
  }
}
</script>

<style scoped lang="scss">
  @import "../style/style";

  .select-doc {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .ui-sub-heading {
      margin: 12px 0;
    }

    &__wr {
      width: 90%;
      margin: 15px auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      flex: auto;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        margin: 15px auto 40px;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      }
    }
  }
</style>
