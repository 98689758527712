<template>
  <div class="doctor" :class="{'active':active,'disable':disable}">
    <img class="doctor__img" :src="(photo !='') ? photo : '../../assets/avatar@2x.png'">
    <p class="doctor__name">{{name}}</p>
    <p class="doctor__title">{{title}}</p>
  </div>
</template>

<script>
export default {
  name: 'SelectDoc',
  props: ['title', 'name', 'active', 'disable', 'photo']
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .doctor {
    font-family: 'Montserrat', sans-serif;
    align-self: center;
    height: 170px;

    &:hover {
      cursor: pointer;

      .doctor__name {
        color: $color-primary;
        font-weight: 600;
      }

      .doctor__title {
        font-weight: 500;
        color: #3e2d2c;
      }
    }

    &__img {
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 80px;
      width: 90px;
      height: 90px;
    }

    &__name {
      font-size: 17px;
      color: #1A1A1A;
      font-weight: 500;
      margin: 15px 0 0 0;
    }

    &__title {
      font-size: 13px;
      color: #707070;
      font-weight: 400;
      margin: 4px 0 0 0;
    }

    &.active {
      .doctor__img {
        border: 1px solid $color-primary;
        padding: 2px;
        width: 84px;
        height: 84px;
      }

      .doctor__name {
        color: $color-primary;
        font-weight: 600;
      }

      .doctor__title {
        font-weight: 500;
        color: #3e2d2c;
      }
    }

    &.disable {
      opacity: 0.6;
      .doctor__img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }

      .doctor__name {
        color: #AAAAAA;
        font-weight: 600;
      }

      .doctor__title {
        font-weight: 500;
        color: #B9B9B9;
      }

      &:hover {
        cursor: default;
      }
    }
  }
</style>
