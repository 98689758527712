<template>
  <WizardWrapper>
    <template v-slot:stepper>
      <StepperHeader :value="4"></StepperHeader>
    </template>
    <template v-slot:calendar>
      <!--        <DatePick/>-->
      <datepicker
        maximum-view="day" monday-first="monday-first" full-month-name="full-month-name"
        v-model="selectedDate"
        :disabled-dates="{days: [0,1,2,3,4,5,6]}"
        :inline="true"
        calendar-class="ui-calendar"
        wrapper-class="ui-calendar-wr mobile-hide"
      ></datepicker>
      <label class="mobile-only ui-label">Current Appointment:</label>
      <div class="ui-label-time-select mobile-only">{{ wizardData.selectedSlot.start_timestamp | moment('MMMM DD dddd') }} &nbsp;at&nbsp; {{ wizardData.selectedSlot.start_timestamp | moment('HH:mm') }} - {{ wizardData.selectedSlot.end_timestamp | moment('HH:mm')
        }}</div>
    </template>
    <template v-slot:infoDoc>
      <DoctorInfo :doc-name="wizardData.selectedDoctor.name" :photo="wizardData.selectedDoctor.photo_url" :doc-title="wizardData.selectedDoctor.title"/>
    </template>
    <template v-slot:infoTime>
      <div class="ui-label-time-select">
        {{ wizardData.selectedSlot.start_timestamp | timeslot }} - {{ wizardData.selectedSlot.end_timestamp | timeslot
        }}
      </div>
    </template>
    <template v-slot:form>
      <div class="form-wr__history">
        <div class="ui-form-heading">Medical History</div>
        <YesNoQst v-for="history in historyList" :key="history.id"
                  :question="history.question"
                  :name="history.id"
                  :has_comment="history.has_comment==1"
                  :comment_placeholder="history.comment_placeholder"
                  v-model="form[history.question]"
        />
      </div>
    </template>
    <template v-slot:buttons>
      <router-link @click.native="goPrev()" to="/select-booking/step2-imageupload" tag="button" class="ui-button ui-button--secondary">Back</router-link>
      <router-link to="/select-booking/step4" :disabled="!valid" @click.native="next()" tag="button" class="ui-button ui-button--primary">
        Next
      </router-link>
    </template>
  </WizardWrapper>
</template>

<script>
import WizardWrapper from '@/components/layout/WizardWrapper'
import DoctorInfo from '@/components/ui/DoctorInfo'
import Datepicker from 'vuejs-datepicker'
import YesNoQst from '@/components/ui/YesNoQst'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import common from '@/api/common'
import StepperHeader from '@/components/ui/StepperHeader'

export default {
  name: 'StepThree',
  components: {
    YesNoQst,
    WizardWrapper,
    Datepicker,
    DoctorInfo,
    StepperHeader
  },
  computed: {
    ...mapGetters(['wizardData', 'historyList', 'images'])
  },
  filters: {
    timeslot: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  methods: {
    ...mapActions(['goPrev', 'goNext', 'loadHistoryList', 'saveHistoryForm']),
    next () {
      this.saveHistoryForm(this.form)
      this.goNext()
    }
  },
  mounted () {
    this.selectedDate = moment(this.wizardData.selectedDate).toDate()
    this.loadHistoryList()
    if (!common.isEmpty(this.wizardData.historyFormData)) {
      this.form = this.wizardData.historyFormData
    }
  },
  data () {
    return {
      selectedDate: new Date(),
      form: {},
      valid: true,
      formOut: {}
    }
  },
  watch: {
    form: {
      handler: function (val) {
        var final = true
        for (const key in this.form) {
          if (this.form[key] === '') {
            final = false
            break
          }
        }
        this.valid = final
        this.saveHistoryForm(this.form)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .form-wr__history {
    display: block;
    position: relative;
    padding-right: 4px;
    @media (min-width: $breakpoint-tablet) {
    }
  }
  .stepper-mobile .step:first-of-type {
    margin-left: calc( 50% - 250px );
  }
</style>
