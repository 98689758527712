<template>
  <div class="custom-select">
    <select :disabled="disabled" :value="model" @change="$emit('update:model', $event.target.value)">
      <slot/>
    </select>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: ['model', 'disabled'],
  model: {
    prop: 'checked',
    event: 'change'
  }
}
</script>

<style scoped lang="scss">
  .custom-select {
    select {
      height: 50px;
      width: 100%;
      border: 1px solid #dedede;
      outline: none;
      border-radius: 40px;
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 16px;
      color: #707070;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      background-color: #FFFFFF;
      -webkit-appearance: none
    }
  }
</style>
