<template>
  <form action="/charge" method="post" id="payment-form">
    <WizardWrapper>
      <template v-slot:stepper>
        <StepperHeader :value="6"></StepperHeader>
      </template>
      <template v-slot:calendar>
        <!--          <DatePick/>-->
        <datepicker
          maximum-view="day" monday-first="monday-first" full-month-name="full-month-name"
          v-model="selectedDate"
          :disabled-dates="{days: [0,1,2,3,4,5,6]}"
          :inline="true"
          calendar-class="ui-calendar"
          wrapper-class="ui-calendar-wr mobile-hide"
        ></datepicker>
        <label class="mobile-only ui-label">Current Appointment:</label>
        <div class="ui-label-time-select mobile-only">{{ wizardData.selectedSlot.start_timestamp | moment('MMMM DD dddd') }} &nbsp;at&nbsp; {{ wizardData.selectedSlot.start_timestamp | moment('HH:mm') }} - {{ wizardData.selectedSlot.end_timestamp | moment('HH:mm') }}</div>
      </template>
      <template v-slot:infoDoc>
        <DoctorInfo :doc-name="wizardData.selectedDoctor.name" :photo="wizardData.selectedDoctor.photo_url" :doc-title="wizardData.selectedDoctor.title"/>
      </template>
      <template v-slot:infoTime>
        <div class="ui-label-time-select">
          {{ wizardData.selectedSlot.start_timestamp | timeslot }} - {{ wizardData.selectedSlot.end_timestamp | timeslot }}
        </div>
      </template>
      <template v-slot:form>
        <div class="form-wr__pay">
          <label class="ui-form-heading">Payment details</label>
          <div class="price">You will be charged:
            <span v-if="parseFloat(wizardData.selectedIssue.price) >0">£{{ wizardData.selectedIssue.price | currency(2) }}</span>
            <span v-else>Complimentary</span>
          </div>
          <div class="form-wr__pay-detail" v-if="parseFloat(wizardData.selectedIssue.price) >0">
            <!-- <div id="card-element">
            </div>

            <div id="card-errors" class="card-error" role="alert"></div> -->
              <stripe-elements
                ref="elementsRef"
                :pk="clinicData.stripe_key"
                :amount="wizardData.selectedIssue.price"
                locale="en"
                @token="tokenCreated"
                @loading="loading = $event"
              />
          </div>
          <div>
            <div id="g-recaptcha"></div>
          </div>
          <div>
            <p class="terms"><input type="checkbox" v-model="refund_agree" :value="false"/>I consent to having a video consultation </p>
            <p class="terms"><input v-model="agree" type="checkbox" :value="false"/>I agree to the Chairsyde Booking System
              <a id="show-modal" @click="showModal = true" class="ui-link">Terms of Use</a> and in particular, that my personal data will
              be processed by Chairsyde and shared with my clinician </p>
          </div>
          <!-- use the modal component, show terms/policy -->
          <modal v-if="showModal" @close="showModal = false">
            <h3 slot="header">Terms of Use</h3>
            <div slot="body" class="modal-terms">
              <iframe style="border:none;" :src="url" id="iframe1" ref="iframe" allowtransparency="true"></iframe>
            </div>
          </modal>
        </div>
      </template>
      <template v-slot:buttons>
        <router-link @click.native="goPrev()" :disabled="proceess" to="/select-booking/step4" tag="button" class="ui-button ui-button--secondary">Back</router-link>
        <button v-if="parseFloat(wizardData.selectedIssue.price) >0" to="/complete" tag="button" :disabled="!(agree && refund_agree) || proceess || !repactureDone" @click="doPayment()" class="ui-button ui-button--dark">
          Confirm
          Payment
        </button>
        <button v-else tag="button" :disabled="!(agree && refund_agree ) || proceess || loading || !repactureDone" @click.prevent="paymentWithout()" class="ui-button ui-button--dark">
          Confirm
        </button>
      </template>
    </WizardWrapper>
  </form>
</template>

<script>
import WizardWrapper from '@/components/layout/WizardWrapper'
import DoctorInfo from '@/components/ui/DoctorInfo'
// import DatePick from '@/components/ui/DatePick'
import Datepicker from 'vuejs-datepicker'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { StripeElements } from 'vue-stripe-checkout'
import Modal from '@/components/ui/Modal'
import StepperHeader from '@/components/ui/StepperHeader'
import { pureApiUrl } from '@/config'

export default {
  name: 'StepFive',
  components: {
    Modal,
    WizardWrapper,
    Datepicker,
    StripeElements,
    DoctorInfo,
    StepperHeader
  },
  computed: {
    ...mapGetters(['wizardData', 'clinicData'])
  },
  filters: {
    timeslot: function (date) {
      return moment(date).format('HH:mm')
    },
    currency: function (value, arg) {
      if ((value + '').includes('.')) {
        var val = (Math.round(value * Math.pow(10, arg)) / Math.pow(10, arg)) + ''
        for (let index = 0; index < (arg - val.split('.')[1].length); index++) {
          val += '0'
        }
      } else {
        val = value + '.'
        for (let index = 0; index < arg; index++) {
          val += '0'
        }
      }
      return val
    }
  },
  methods: {
    ...mapActions(['goPrev', 'goNext', 'processPayment', 'processPaymentWithoutPayment']),
    async paymentWithout () {
      this.proceess = true
      try {
        var result = await this.processPaymentWithoutPayment(this.repactureToken)
        if (result.success) {
          this.$router.push('/complete')
        } else {
          this.$router.push('/error')
        }
      } catch (error) {
        if (error.response.data.errors && error.response.data.errors.booking_widget_settings && error.response.data.errors.booking_widget_settings.includes('Minimum images count not uploaded.')) {
          this.$router.push('/settings-error')
        } else {
          this.$router.push('/error')
        }
      } finally {
        this.proceess = false
      }
    },
    doPayment () {
      if (this.$refs.submitButtonRef && !this.loading) this.$refs.submitButtonRef.submit()
      setTimeout(() => { this.proceess = true }, 10)
      // this.proceess = true
    },
    async tokenCreated (token) {
      this.proceess = true
      try {
        if (this.payment_inprocess) return
        this.payment_inprocess = true
        var result = await this.processPayment({ token: token.id, captchaToken: this.repactureToken })
        if (result.success) {
          this.$router.push('/complete')
        } else {
          this.$router.push('/error')
        }
      } catch (error) {
        if (error.response.data.errors && error.response.data.errors.booking_widget_settings && error.response.data.errors.booking_widget_settings.includes('Minimum images count not uploaded.')) {
          this.$router.push('/settings-error')
        } else {
          this.$router.push('/error')
        }
      } finally {
        this.proceess = false
      }
    }
  },
  data () {
    return {
      showModal: false,
      loadingTerms: false,
      selectedDate: new Date(),
      // publishableKey: stripeToken,
      repactureDone: false,
      repactureToken: null,
      loading: false,
      payment_inprocess: false,
      agree: false,
      refund_agree: false,
      proceess: false,
      url: `${pureApiUrl}/booking-term`
    }
  },
  beforeDestroy () {
    clearInterval(window.timer)
  },
  mounted () {
    this.selectedDate = moment(this.wizardData.selectedDate).toDate()
    var vue = this
    setInterval(() => {
      const errorElement = document.getElementById('card-errors')
      if (!errorElement) {
        return
      }
      vue.proceess = errorElement.textContent !== ''
    }, 100)
    window.grecaptcha.render('g-recaptcha', {
      sitekey: this.clinicData.site_key
    })
    window.timer = setInterval(() => {
      this.repactureToken = document.getElementsByName('g-recaptcha-response')[0].value
      this.repactureDone = this.repactureToken !== ''
    }, 10)
    // // Create a Stripe client.
    // // eslint-disable-next-line no-undef
    // var stripe = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx')

    // // Create an instance of Elements.
    // var elements = stripe.elements()

    // // Custom styling can be passed to options when creating an Element.
    // // (Note that this demo uses a wider set of styles than the guide below.)
    // var style = {
    //   base: {
    //     color: '#1A1A1A',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     fontFamily: '"Montserrat", sans-serif',
    //     fontWeight: '600',
    //     fontSmoothing: 'antialiased',
    //     fontSize: '16px',
    //     '::placeholder': {
    //       fontWeight: '500',
    //       color: '#1a1a1a99'
    //     }
    //   },
    //   invalid: {
    //     color: '#fa755a',
    //     iconColor: '#fa755a'
    //   }
    // }
    // // Create an instance of the card Element.
    // var card = elements.create('card', { style: style })

    // // Add an instance of the card Element into the `card-element` <div>.
    // card.mount('#card-element')

    // // Handle real-time validation errors from the card Element.
    // card.on('change', function (event) {
    //   var displayError = document.getElementById('card-errors')
    //   if (event.error) {
    //     displayError.textContent = event.error.message
    //   } else {
    //     displayError.textContent = ''
    //   }
    // })

    // Submit the form with the token ID.
    // function stripeTokenHandler (token) {
    //   // Insert the token ID into the form so it gets submitted to the server
    //   var form = document.getElementById('payment-form')
    //   var hiddenInput = document.createElement('input')
    //   hiddenInput.setAttribute('type', 'hidden')
    //   hiddenInput.setAttribute('name', 'stripeToken')
    //   hiddenInput.setAttribute('value', token.id)
    //   form.appendChild(hiddenInput)
    //   form.submit()
    // }
  }
}
</script>

<style>
  .hide {
    visibility: visible !important;
    display: none !important;
  }
</style>
<style scoped lang="scss">
  @import '../../style/style';

  .form-wr__pay {
    display: flex;
    flex-direction: column;
    height: inherit;
    min-height: 250px;
    align-items: center;
    justify-content: space-between;

    &-detail {
      max-width: 440px;
      width: 100%;
      background-color: #ffffff;
      border-radius: 20px;
      margin-top: 18px;
      margin-bottom: 10px;
      padding: 6% 5%;
    }

    .price {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      color: #707070;
      font-size: 16px;
      overflow: hidden;
      margin-top: 8px;
      text-align: left;

      span {
        color: #1A1A1A;
        font-weight: 600;
        padding-left: 6px;
      }

      .currency {
        color: #707070;
        font-weight: 600;
        font-size: 14px;
        margin-left: 4px;
      }
    }

    .terms {
      font-size: 11px;
      font-family: 'Montserrat', sans-serif;
      color: #1A1A1A;
      display: block;
      position: relative;
      padding-left: 40px;
      line-height: 18px;
      text-align: left;

      input[type='checkbox'] {
        margin: 1px 10px 0 -30px;
        position: absolute;
        width: 18px;
        height: 18px;
      }

    }

    @media (min-width: $breakpoint-tablet) {
    }
  }

  .form-wr__pay-detail{
    padding: 0;
  }

  /**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
  .StripeElement {
    box-sizing: border-box;

    height: 40px;

    padding: 10px 0px;

    border-bottom: 1px solid #707070;
    border-radius: 0;
    background-color: white;

    box-shadow: none;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: none;
    border-color: #dd2895;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .card-error {
    margin-top: 15px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
  }
  .stepper-mobile .step:first-of-type {
    margin-left: calc( 50% - 450px );
  }
  .modal-terms {
    p {
      line-height: 1.5;
      span {
        text-decoration: underline;
        display: block;
        margin-bottom: 12px;
        font-weight: 500;
      }
    }
    ul {
      padding: 5px 0 0 15px;
      li {
        padding-bottom: 10px;
      }
    }
  }
</style>
