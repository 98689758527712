<template>
  <div class="wizard-wr">
      <slot name="stepper"/>
    <!-- <div class="wizard-wr__stepper">
    </div> -->
    <div class="clearfix">
      <div class="wizard-wr__calendar">
        <slot name="calendar"/>
      </div>
      <div class="wizard-wr__form" :class="loadingState | loading">
        <slot name="form"/>
      </div>
    </div>
    <div class="wizard-wr__actions">
      <slot name="buttons"/>
    </div>
    <div class="wizard-wr__info">
      <slot name="infoDoc"/>
      <slot name="infoTime"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WizardWrapper',
  props: ['loader'],
  filters: {
    loading: value => value ? 'loading' : ''
  },
  computed: {
    ...mapGetters(['loadingState'])
  }
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .wizard-wr {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-top: 40px;
    position: relative;

    @media (min-width: $breakpoint-tablet) {
      padding: 0 5% 0 7%;
      margin-top: 10px;
    }

    @media screen and (max-width: $breakpoint-tablet-ex) and (min-width: $breakpoint-tablet) {
      padding: 0 30px;
    }

    .clearfix {
      height: 100%;
      position: relative;
    }
  }

  .wizard-wr__stepper {
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 0;
    align-items: center;

    .ui-heading {
      padding-left: 4px;
      padding-bottom: 12px;
      padding-top: 35px;
    }

    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0 40px 0;
      .ui-heading {
        padding-top: 20px;
      }
    }
  }

  .wizard-wr__calendar {
    @media (min-width: $breakpoint-tablet) {
      float: left;
      width: 40%;
      margin: 0;
    }
  }

  .wizard-wr__form {
    position: relative;
    padding: 15px 10px 20px;

    &.loading {
      // padding: 30px;
      opacity: 0.6;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        border: 6px solid #dedede; /* Light grey */
        border-top: 6px solid #1A1A1A; /* Blue */
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        position: absolute;
        z-index: 100;
        margin: 20% 0 0 -20px;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
    @media (min-width: $breakpoint-tablet) {
      float: right;
      width: 60%;
      margin: 0;
      padding: 2px 0 0 0;
      overflow: auto;
      height: 100%;
      border-radius: 0 0 25px 25px;
    }
    @media screen and (max-width: $breakpoint-tablet-ex) and (min-width: $breakpoint-tablet) {
      width: 59%;
    }
  }

  .wizard-wr__actions {
    align-self: center;
    display: flex;
    width: 95%;
    .ui-button {
      flex-grow: 1;
    }
    @media (min-width: $breakpoint-tablet) {
      align-self: flex-end;
      padding: 10px 0;
      width: auto;
    }
  }

  .wizard-wr__info {
    position: absolute;
    bottom: 16px;
    display: none;
    opacity: 0;
    width: 0;
    @media (min-width: $breakpoint-tablet) {
      opacity: 1;
      width: auto;
      display: flex;
      /*display: inline-block;*/
      align-items: center;
      justify-content: space-between;
    }
  }
  .wizard-wr__info .ui-label-time-select {
    width: auto;
    margin-left: 4px;
    display: inline-block;

  }
</style>
