<template>
  <div class="doctor">
    <img class="doctor__img" :src="(photo !='') ? photo : '../../assets/avatar@2x.png'">
    <p class="doctor__name">Dr {{docName}}</p>
    <p class="doctor__title">{{docTitle}}</p>
  </div>
</template>

<script>
export default {
  name: 'DoctorInfo',
  props: ['docName', 'docTitle', 'photo']
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .doctor {
    font-family: 'Montserrat', sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 44px;
    overflow: visible;
    max-width: 180px;

    &__img {
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 80px;
      position: absolute;
      left: 0;
      top: 0;
      width: 44px;
      height: 44px;
    }

    &__name {
      font-size: 11px;
      color: #1A1A1A;
      font-weight: 600;
      margin: 0 0 0 52px;
      max-width: 126px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: initial;
      white-space: nowrap;
    }

    &__title {
      font-size: 10px;
      color: #a3a3a3;
      font-weight: 500;
      margin: 2px 0 0 52px;
      word-break: break-word;
      width: 70%;
      text-align: left;
    }
  }
</style>
