/* eslint-disable comma-dangle */
<template>
  <WizardWrapper>
<!--  WizardWrapper  loading="loading"-->
    <template v-slot:stepper>
      <StepperHeader :value="1"></StepperHeader>
    </template>
    <template v-slot:calendar>
      <!--        <DatePick/>-->
                  <!-- :highlighted="{days: [5]}" -->
      <datepicker maximum-view="day" monday-first="monday-first" full-month-name="full-month-name"
                  :disabled-dates="{to: yesterday}"
                  :highlighted="{dates: bookingDates}"
                  v-model="selectedDate" @click.native="pickADate" :inline="true" calendar-class="ui-calendar"
                  wrapper-class="ui-calendar-wr"></datepicker>
      <label class="mobile-only ui-label">Select Date & Time:</label>
    </template>
    <template v-slot:infoDoc>
      <DoctorInfo :doc-name="wizardData.selectedDoctor.name" :photo="wizardData.selectedDoctor.photo_url" :doc-title="wizardData.selectedDoctor.title"/>
    </template>
    <template v-slot:form>
      <div class="form-wr__time">
        <TimeSelect  name="time" :checked="wizardData.selectedSlot.id==slot.id" @click.native="(!slot.is_available) ? false:sellectASlot(slot)"
                      v-for="slot in slotList" :key="slot.id" :disabled="!slot.is_available">{{ slot.start_timestamp |
            timeslot }} - {{ slot.end_timestamp | timeslot }}
        </TimeSelect>
        <div class="no-slots" v-if="slotList.length < 1 && !loadingState"><label>
          There are no video consultations available for this day, please select another day
        </label></div>
      </div>
      <!-- {{slotList}} -->
    </template>
    <template v-slot:buttons>
      <router-link @click.native="goPrev()" to="/select-doc" tag="button" class="ui-button ui-button--secondary">Back</router-link>
      <router-link @click.native="goNext()" to="/select-booking/step2" :disabled="!wizardData.selectedSlot.id" tag="button" class="ui-button ui-button--primary">Next</router-link>
    </template>
  </WizardWrapper>
</template>

<script>
import WizardWrapper from '@/components/layout/WizardWrapper'
import TimeSelect from '@/components/ui/TimeSelect'
import DoctorInfo from '@/components/ui/DoctorInfo'
import Datepicker from 'vuejs-datepicker'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import common from '@/api/common'
import StepperHeader from '@/components/ui/StepperHeader'

export default {
  name: 'StepOne',
  components: {
    TimeSelect,
    WizardWrapper,
    Datepicker,
    DoctorInfo,
    StepperHeader
  },
  methods: {
    ...mapActions(['goPrev', 'goNext', 'loadSlotList', 'sellectADate', 'sellectASlot', 'loadDateList']),
    pickADate () {
      this.loading = true
      this.sellectASlot({})
      this.loadSlotList(this.formatDate(this.selectedDate))
      this.sellectADate(this.formatDate(this.selectedDate))
      this.loading = false
    },
    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    }
  },
  computed: {
    ...mapGetters(['wizardData', 'loadingState', 'slotList', 'dateList'])
  },
  filters: {
    timeslot: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  mounted () {
    if (!common.isEmpty(this.wizardData.selectedDate)) {
      this.selectedDate = moment(this.wizardData.selectedDate).toDate()
    }
    this.loadDateList()
    this.pickADate()
    // console.log(this.wizardData.selectedDate)
  },
  data () {
    return {
      yesterday: moment(new Date()).subtract(1, 'days').toDate(),
      bookingDates: [],
      selectedDate: new Date(),
      loading: false
    }
  },
  watch: {
    dateList (val) {
      this.bookingDates = []
      val.forEach(element => {
        this.bookingDates.push(new Date(element))
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .form-wr__time {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
    min-height: 200px;
    position: relative;
    grid-auto-rows: max-content;

    .no-slots {
      background-color: transparent;
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      align-items: center;
      justify-content: center;
      padding: 30px 60px;

      label {
        font-family: 'Montserrat', sans-serif;
        border-radius: 60px;
        background-color: #989898b5;
        padding: 22px 30px;
        font-size: 14px;
        color: #ffffff;
        font-weight: 500;
        box-shadow: 0 0 40px 30px #6868682b;
      }
    }

    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: auto auto auto auto;
      grid-gap: 12px;
      padding: 0 0;
    }
  }
  .stepper-mobile .step:first-of-type {
    margin-left: calc( 50% - 50px );
  }
</style>
