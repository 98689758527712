<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <a class="modal-default-button ui-button ui-button--dark" @click="$emit('close')">
                I Agree
              </a>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: ['title', 'body']
}
</script>

<style scoped lang="scss">
  @import '../../style/style';
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #F8F8F8;
    border-radius: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: 'Montserrat', sans-serif;
    /*overflow: auto;*/
    position: relative;
    @media (min-width: $breakpoint-tablet) {
      width: 60%;
      height: 75%;
      border-radius: 20px;
    }
  }

  .modal-header {
    margin-top: 0;
    color: #1A1A1A;
    font-weight: 400;
    text-align: left;
  }

  .modal-body {
    margin: 10px 0;
    color: #1A1A1A;
    font-weight: 400;
    text-align: left;
    font-size: 13px;
    height: 75%;
    width: 93%;
    position: absolute;
    // overflow: auto;
  }

  .modal-default-button {
    line-height: 38px;
    position: absolute;
    bottom: 30px;
    right: 40px;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

</style>
<style lang="scss">
.modal-body iframe{
  height: 75vh;
  width:100%;
  @media (min-width: 768px) {
    height: 40vh;
    width:101%;
  }
}
</style>
