<template>
  <WizardContentWrapper>
    <template v-slot:default>
      <!--<div>
        <h1>
          wizard
          &lt;!&ndash;/**https://www.5balloons.info/simple-multi-step-form-wizard-with-validation-in-vuejs/
          https://github.com/webnoobcodes/vuejs-multistepform*/&ndash;&gt;
        </h1>
      </div>-->
      <router-view></router-view>
    </template>
  </WizardContentWrapper>
</template>

<script>
import WizardContentWrapper from '@/components/layout/WizardContentWrapper'

export default {
  name: 'BookAppWizard',
  components: { WizardContentWrapper }
}
</script>

<style scoped>

</style>
