<template>
  <ContentWrapper form-title="Something went wrong">
    <template v-slot:default>
      <div class="welcome-wr">
        <h1 >{{title}}</h1>
        <p>{{title}}</p>
      </div>
    </template>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from '@/components/layout/ContentWrapper'
import { mapGetters } from 'vuex'

export default {
  name: 'BookAppError',
  components: { ContentWrapper },
  computed: {
    ...mapGetters(['wizardData', 'finalResult'])
  }
}
</script>

<style scoped>

</style>
