<template>
  <ContentWrapper :form-title="title">
    <template v-slot:default>
      <div class="welcome-wr">
      </div>
    </template>
    <template v-slot:buttons>
      <button @click="resetWizardData ()" class="ui-button ui-button--dark">Try again</button>
    </template>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from '@/components/layout/ContentWrapper'
import { mapGetters } from 'vuex'

export default {
  name: 'BookAppError',
  components: { ContentWrapper },
  data () {
    return {
      title: 'Your clinic is not allowed to have booking widget'
    }
  },
  computed: {
    ...mapGetters(['wizardData', 'finalResult']),
    getErrorMessage: function () {
      var errorMessage = ''
      for (const key in this.finalResult.errors) {
        errorMessage = this.finalResult.errors[key][0]
      }
      return errorMessage
    }
  },
  methods: {
    resetWizardData () {
      location.reload()
    }
  },
  mounted () {
    const vue = this
    if (this.$route.query.type && this.$route.query.type === 'blocked') {
      vue.title = 'Related group of this clinic had been blocked.'
    }
  }
}
</script>

<style scoped>

</style>
