<template>
  <div class="content-wrapper">
    <div class="content-wrapper__header">
      <div class="ui-heading">{{formTitle}}</div>
    </div>
    <div class="content-wrapper__body">
      <slot class="slot" />
    </div>
    <div class="content-wrapper__action">
      <slot name="buttons"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardContentWrapper',
  // props: ['formTitle']
  props: {
    formTitle: String
  }
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .content-wrapper {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-radius: 30px;
    height: 100%;
    max-width: 1060px;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    overflow: auto;

    @media (min-width: $breakpoint-tablet) {
      box-shadow: 0px 3px 20px #00000029;
    }

    .content-wrapper__body {
      &.loading {
        // padding: 30px;
        opacity: 0.6;
        position: relative;
        &::before {
          content: '';
          display: inline-block;
          border: 6px solid #dedede; /* Light grey */
          border-top: 6px solid #1A1A1A; /* Blue */
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 2s linear infinite;
          position: absolute;
          z-index: 100;
          margin: 20% 0 0 -20px;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      }
    }
    /* scrollbar parent */
    &::-webkit-scrollbar {
      width: 10px;
      background: #000000;
      position: relative;
      display: block;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #909090;
      position: absolute;
      margin: 30% 0 20%;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #686868;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &__header {
      padding: 0;
      @media (min-width: $breakpoint-tablet) {
        padding: 35px 0 0;
      }
    }

    &__body {
      background-color: transparent;
      height: 100%;

      form {
        padding: 0;
        margin: 0;
        display: unset;
      }

      @media (min-width: $breakpoint-tablet) {
        min-height: 1px;
      }
    }

    &__action {
      padding-bottom: 26px;
      box-sizing: border-box;
    }
  }
</style>
