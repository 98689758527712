<template>
  <div>
    <label class="container">
      <input type="checkbox" :checked="value" @change="handleInput($event)">
      <span class="checkmark"></span>
      <span class="text ui-label">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    label: String,
    value: {
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../style/style';
  /* Customize the label (the container) */
  .container {
    display: flex;
    position: relative;
    padding-left: 50px;
    margin: 15px 0 2px;
    min-height: 30px;
    cursor: pointer;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: fit-content;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background-color: #FFFFFF;
    box-shadow: inset 0px 0px 0px 1px #DEDEDE;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    border-color: $color-primary;
    box-shadow: inset 0px 0px 0px 1px $color-primary;
    /*box-shadow: none;*/
  }

  .container:hover input ~ .checkmark:after {
    display: block;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $color-primary;
    box-shadow: 2px 2px 3px $color-shadow;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 11px;
    top: 7px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>
