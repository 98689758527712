<template>
  <div class="ui-qst">
    <div class="ui-qst-row">
      <label class="ui-label">{{ question }}</label>
      <div>
        <!-- <TimeSelect :name="name" value="Y" :checked="model" @click.native="$emit('update:model', true)">Yes</TimeSelect> -->
        <!-- <TimeSelect :name="name" value="N" :checked="!model" @click.native="$emit('update:model', false)">No</TimeSelect> -->
        <label class="container ui-button--time-select">
          <input type="radio" :name="name" :value="has_comment ? '' : 'Yes'" :checked="value!='No' && value!=null" @change="handleInput($event)">
          <span class="checkmark"></span>
          <span class="text">Yes</span>
        </label>
        <label class="container ui-button--time-select">
          <input type="radio" :name="name" value="No" :checked="value=='No' || value==null" @change="handleInput($event)">
          <span class="checkmark"></span>
          <span class="text">No</span>
        </label>
      </div>
    </div>
    <textarea maxlength="200" v-show="value!='No' && value!=null && has_comment" :class="{'error':value.length<1}" :value="value" @input="handleInput($event)" rows="4" class="ui-textarea" :placeholder="comment_placeholder"></textarea>
  </div>
</template>

<script>
export default {
  name: 'YesNoQst',
  props: {
    question: {
      type: String
    },
    value: {
      type: String,
      default: 'No'
    },
    name: {
      type: Number
    },
    comment_placeholder: {
      type: String
    },
    has_comment: {
      type: Boolean
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  },
  mounted () {
    this.$emit('input', 'No')
  }
  //
}
</script>

<style scoped lang="scss">
  @import '../../style/style';

  .ui-qst {
    display: flex;
    flex-direction: column;

    &-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0 10px;

      @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
      }

      div {
        display: flex;
        flex-direction: row;

        .ui-button--time-select {
          margin: 0 10px 0 2px;
        }
      }

      .ui-label {
        text-align: center;
        @media (min-width: $breakpoint-tablet) {
          text-align: left;
        }
      }
    }

    .ui-textarea {
      width: auto;
      flex: 1;
      margin-right: 10px;
      &.error{
        border-color: #707070;
        color:#707070;
        ::placeholder {
          color: red;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: red;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
        color: red;
        }
      }
    }
  }

  @import '../../style/style';
  /* The container */
  .ui-button--time-select {
    cursor: default;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin: 0;
    padding: 0;
    min-width: 90px;
    height: 38px;
    box-shadow: none;
    border-radius: 0;
    overflow: visible;
    /* Hide the browser's default radio button */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 38px;
      width: 100%;
      width: calc(100% - 4px);
      margin: 1px 2px 3px 2px;
      display: inline-block;
      border-radius: 30px;
      background-color: #FFFFFF;
      box-shadow: inset 0px 0px 0px 1px #DEDEDE;
      z-index: 0;
      cursor: pointer;
    }

    .text {
      z-index: 1;
      font-size: 12px;
      color: #707070;
      margin-top: 2px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      cursor: pointer;
    }
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: $color-primary;
    box-shadow: none;
  }

  .container:hover input ~ .text {
    color: #FFFFFF;
  }

  /* When the radio button is checked */
  .container input:checked ~ .checkmark {
    background-color: $color-primary;
    box-shadow: 5px 5px 15px $color-shadow;
    cursor: default;
  }

  .container input:checked ~ .text {
    color: #FFFFFF;
    cursor: default;
  }

  /* When the radio button is disabled */
  .container input:disabled ~ .checkmark {
    background-color: #EDEDED;
    box-shadow: none;
    cursor: default;
  }

  .container input:disabled ~ .text {
    color: #B9B9B9;
    cursor: default;
  }
</style>
